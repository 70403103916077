// import './vendor/body-scroll-lock';
import smoothscroll from './vendor/smoothscroll';

import sal from './vendor/sal';
import Pristine from './vendor/pristine';

window.sal = sal;
window.Pristine = Pristine;

smoothscroll.polyfill();
